:root {
  --sr-colors-blue: rgba(42, 63, 251, 1);
  --sr-colors-blue-70: rgba(42, 63, 251, 0.7);
  --sr-colors-blue-50: rgba(42, 63, 251, 0.5);
  --sr-colors-blue-10: rgba(42, 63, 251, 0.1);
  --sr-colors-button-gray: rgba(228, 235, 241, 1);
  --sr-colors-surface-gray: rgba(242, 247, 252, 1);
  --sr-colors-error: rgba(251, 16, 100, 1);
  --sr-colors-success: rgba(67, 225, 143, 1);
  --sr-colors-warning: rgba(251, 230, 42, 1);
  --sr-colors-black: rgba(0, 0, 0, 1);
  --sr-colors-black-70: rgba(0, 0, 0, 0.7);
  --sr-colors-black-50: rgba(0, 0, 0, 0.5);
  --sr-colors-black-30: rgba(0, 0, 0, 0.3);
  --sr-colors-white: rgba(255, 255, 255, 1);

  /* Copied from modern-normalize + inter */
  --sr-fonts-body: Inter, system-ui, -apple-system, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  --sr-fonts-heading: var(--sr-fonts-body);

  --sr-font-sizes-base: 16px;
  --sr-font-sizes-heading: 18px;

  --sr-font-weights-body: 400;
  --sr-font-weights-bold: 600;
  --sr-font-weights-heading: 600;

  --sr-line-heights-body: 1.5;
  --sr-line-heights-heading: 1.3;

  --sr-radii-small: 4px;
}

/* Default global text styles */
body {
  font-family: var(--sr-fonts-body);
  font-size: var(--sr-font-sizes-base);
  font-weight: var(--sr-font-weights-body);
  line-height: var(--sr-line-heights-body);
  color: var(--sr-colors-text);
  background-color: var(--sr-colors-surface-gray);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Fullscreen spinner layout and animation */
#fullscreen-spinner {
  display: none;
  z-index: 0;
}
#fullscreen-spinner .spinner {
  will-change: transform;
}
#fullscreen-spinner .spinner .path {
  will-change: stroke-dasharray, stroke-dashoffset;
}

#fullscreen-spinner.visible {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
}
#fullscreen-spinner.visible .spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 50px;
  height: 50px;
}
#fullscreen-spinner.visible .spinner .path {
  stroke: black;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
